<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { PageLayout, TextInput, TextArea, GModal, GButton } from "@/components";
import { useI18n } from "vue-i18n";
import { useAiConfigStore } from "@/stores/aiConfig";

const { t } = useI18n();

const organisationName = ref("");
const organisationValues = ref("");
const openModal = ref(false);
const modalToShow = ref("");

const loading = ref(true);
const { getAiConfigSettings, updateAiConfigSettings } = useAiConfigStore();

const data = await getAiConfigSettings();

watchEffect(() => {
  if (data?.value) {
    organisationName.value = data.value.organisationCommonName;
    organisationValues.value = data.value.organisationalValues?.replaceAll(
      "\\n",
      "\n",
    );
    loading.value = false;
  }
});

const showModal = (modal: string) => {
  modalToShow.value = modal;
  openModal.value = true;
};

const organisationNameLengthHint = computed(() => {
  const length = organisationName.value.length;
  if (length > 20) {
    return `${length} ${t(
      "ai-config.organisation-settings.organisation_name_hint_length",
    )}`;
  }
  return "";
});

const organisationValuesCharacterCounter = computed(() => {
  const length = organisationValues.value.length;
  if (length > 0) {
    return `${length} ${t("ai-config.organisation-settings.characters")}`;
  }
  return "";
});

const updateConfig = async () => {
  loading.value = true;
  await updateAiConfigSettings(
    organisationName.value,
    organisationValues.value,
  );
  loading.value = false;
};
</script>

<template>
  <PageLayout
    :title="t('ai-config.organisation-settings.title')"
    :heading="t('ai-config.organisation-settings.title')"
    :loading="loading"
  >
    <div class="space-y-8 border-t pt-6">
      <TextInput
        autocomplete="off"
        name="organisationName"
        :label="t('ai-config.organisation-settings.organisation_name')"
        v-model="organisationName"
        inputClasses="max-w-[20rem]"
        :placeholder="
          t('ai-config.organisation-settings.organisation_name_placeholder')
        "
      >
        <template #labelIcon>
          <button @click="showModal('organisation_name')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="-mt-1 inline-block h-5"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12Zm8.5-4A1.5 1.5 0 0 1 12 6.5h.01a1.5 1.5 0 0 1 1.5 1.5v.01a1.5 1.5 0 0 1-1.5 1.5H12a1.5 1.5 0 0 1-1.5-1.5V8Zm1.5 3a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </template>
        <template #before>
          <p class="mb-2 text-sm text-gray-500">
            {{ t("ai-config.organisation-settings.organisation_name_hint") }}
          </p>
        </template>
        <template #after v-if="organisationNameLengthHint">
          <p class="ml-2 mt-1 text-sm text-gray-500">
            {{
              t("ai-config.organisation-settings.organisation_name_hint_length")
            }}
          </p>
        </template>
      </TextInput>
      <TextArea
        name="values"
        :label="t('ai-config.organisation-settings.organisation_values')"
        v-model="organisationValues"
        container-classes="w-full"
        input-classes="h-48 xl:h-72"
        :maxlength="3000"
        :placeholder="
          t(`ai-config.organisation-settings.organisation_values_placeholder`)
        "
      >
        <template #labelIcon>
          <button @click="showModal('organisation_values')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="-mt-1 inline-block h-5"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12Zm8.5-4A1.5 1.5 0 0 1 12 6.5h.01a1.5 1.5 0 0 1 1.5 1.5v.01a1.5 1.5 0 0 1-1.5 1.5H12a1.5 1.5 0 0 1-1.5-1.5V8Zm1.5 3a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </template>
        <template #before>
          <p class="mb-2 text-sm text-gray-500">
            {{ t("ai-config.organisation-settings.organisation_values_hint") }}
          </p>
        </template>
        <template #after>
          <p class="ml-1 mt-2 text-sm text-gray-500">
            {{ organisationValuesCharacterCounter }}
          </p>
        </template>
      </TextArea>
      <GButton
        :disabled="!organisationName || !organisationValues"
        @click="updateConfig"
      >
        {{ t("common.save") }}
      </GButton>
    </div>
    <GModal
      v-if="modalToShow"
      :isOpen="openModal"
      :title="t(`ai-config.organisation-settings.${modalToShow}`)"
      @close="openModal = false"
      class="md:w-[26rem]"
    >
      <div
        v-html="t(`ai-config.organisation-settings.${modalToShow}_description`)"
      ></div>
    </GModal>
  </PageLayout>
</template>
