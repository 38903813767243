<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { startOfDay, endOfDay, endOfToday, sub } from "date-fns";

import { isDateTuple } from "@/utils/typeGuards";
import { GDatePicker } from "@/components";
import { PresetId } from "./useDatePicker";

const { t } = useI18n();

const emit = defineEmits<{ (event: "change", value: [Date, Date]): void }>();

const startDate = ref(startOfDay(sub(new Date(), { days: 6 })));
const endDate = ref(endOfToday());


const setDateInterval = (value: Date | [Date, Date] | null) => {
  if (isDateTuple(value)) {
    startDate.value = startOfDay(value[0]);
    endDate.value = endOfDay(value[1]);
    emit("change", [startDate.value, endDate.value]);
  }
};

emit("change", [startDate.value, endDate.value]);
</script>
<template>
  <GDatePicker
    :label="t('common.choose_date_interval')"
    :modelValue="[startDate, endDate]"
    @update:modelValue="setDateInterval"
    :presetRanges="[
      PresetId.LAST_12_MONTHS,
      PresetId.LAST_30_DAYS,
      PresetId.LAST_7_DAYS,
    ]"
    withButtonPresets
    withCustomRangeLabel
    :noToday="false"
  ></GDatePicker>
</template>
