import { defineStore } from "pinia";
import { ref } from "vue";
import {
  breakpointsTailwind,
  useBreakpoints as vueUseBreakpoints,
} from "@vueuse/core";

export const useUiStore = defineStore("menu", () => {
  const tableRef = ref();
  const breakpoints = vueUseBreakpoints(breakpointsTailwind);
  const isMenuCollapsible = breakpoints.smallerOrEqual("lg");
  const isMenuExpanded = ref(breakpoints.greater("lg"));

  function toggleDashboardMenu(value?: boolean) {
    if (value !== undefined) isMenuExpanded.value = value;
    else isMenuExpanded.value = !isMenuExpanded.value;
  }

  const isLoading = ref(false);
  const setIsLoading = (value: boolean) => {
    isLoading.value = value;
  };

  const initializationError = ref<Error | undefined>(undefined);

  return {
    tableRef,
    isMenuCollapsible,
    isMenuExpanded,
    isLoading,
    toggleDashboardMenu,
    setIsLoading,
    initializationError,
  };
});
