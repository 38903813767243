<script setup lang="ts">
import { GTag } from "@/components";
import { NodeViewWrapper, type NodeViewProps } from "@tiptap/vue-3";

const props = defineProps<NodeViewProps>();
</script>

<template>
  <NodeViewWrapper class="inline-block rounded-md mb-2" ref="nodeViewWrapperRef">
    <GTag
      :text="props.node.attrs.text"
      :variant="props.node.attrs.hasError ? 'error' : 'neutral'"
      dismissible
      data-testid="email-tag"
      :disabled="!props.editor.isEditable"
      @dismiss="props.deleteNode()"
    />
  </NodeViewWrapper>
</template>
<style scoped>
/* * {
  &.ProseMirror-selectednode {
    @apply outline outline-2 outline-offset-2;

    &:has(.text-error) {
      @apply outline-error;
    }

    &:has(.text-neutral) {
      @apply outline-black;
    }
  }
} */
</style>
