<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import {
  useFloating,
  offset,
  autoUpdate,
  detectOverflow,
} from "@floating-ui/vue";
import { storeToRefs } from "pinia";

import {
  ActionMenu,
  GDropdownIconButtonTrigger,
  GDropdownButtonTrigger,
} from "@/components";
import { useBreakpoints } from "@/composables";
import { type ActionOption } from "@/patterns";
import { useUiStore } from "@/stores";

import CloseIcon from "@/assets/images/icons/close.svg";
import EllipsisVerticalIcon from "@/assets/images/icons/ellipsis-vertical.svg";

const props = defineProps<{
  selectedItems: unknown[];
  actions: ActionOption[];
}>();

defineEmits<{
  (event: "deselectItems"): void;
}>();

const { t } = useI18n();

const uiStore = useUiStore();
const { tableRef } = storeToRefs(uiStore);
const actionBarRef = ref<HTMLDialogElement>();

watchEffect(() => {
  if (actionBarRef.value) {
    props.selectedItems?.length > 0
      ? actionBarRef.value.show()
      : actionBarRef.value.close();
  }
});

const { isSmall, isLarge } = useBreakpoints({
  includeSmallerBreakpoints: true,
});

const responsiveActions = computed(() => {
  if (isSmall.value) {
    return {
      primaryActions: [],
      secondaryActions: props.actions,
    };
  }
  if (isLarge.value) {
    return {
      primaryActions: props.actions.slice(0, 4),
      secondaryActions: props.actions.slice(4),
    };
  }
  return {
    primaryActions: props.actions,
    secondaryActions: [],
  };
});

const { floatingStyles } = useFloating(tableRef, actionBarRef, {
  placement: "bottom",
  strategy: "fixed",
  whileElementsMounted: autoUpdate,
  middleware: [
    offset(-90),
    {
      name: "middleware",
      async fn(state) {
        const overflow = await detectOverflow(state);
        if (overflow.bottom > -90) return { y: state.y - overflow.bottom - 80 };
        return {};
      },
    },
  ],
});
</script>
<template>
  <dialog
    ref="actionBarRef"
    :style="floatingStyles"
    class="action-dialog m-0 rounded-lg bg-primary p-3 shadow-lg"
  >
    <div
      class="flex place-items-center gap-2 border-r border-blue-70 px-4 py-2 text-white"
    >
      <span class="my-auto">
        {{ selectedItems?.length || 0 }} {{ t("common.selected") }}
      </span>
      <CloseIcon
        class="h-4 w-4 cursor-pointer"
        @click="$emit('deselectItems')"
        :title="t('common.close')"
        data-testid="close-modal-button"
      />
    </div>
    <button
      v-for="{ label, action } in responsiveActions.primaryActions"
      :key="label"
      @click="action"
      class="p-2 text-white first-of-type:ml-4 last-of-type:mr-4 hover:underline"
    >
      {{ label }}
    </button>
    <ActionMenu
      v-if="responsiveActions.secondaryActions.length > 0"
      :options="responsiveActions.secondaryActions"
      :activeAction="0"
      class="border-l border-blue-70 pl-3"
    >
      <template #trigger="{ setElementRef, isMenuOpen }">
        <GDropdownButtonTrigger
          v-if="isSmall"
          singleSelectedOptionLabel="Action"
          :ref="setElementRef"
          :isMenuOpen="isMenuOpen"
          variant="secondary"
        />
        <GDropdownIconButtonTrigger
          v-else
          :icon="EllipsisVerticalIcon"
          :ref="setElementRef"
          variant="secondary"
        />
      </template>
    </ActionMenu>
  </dialog>
</template>
<style>
.action-dialog[open] {
  opacity: 1;
  display: flex;
}
.action-dialog {
  opacity: 0;
  display: none;
  transition:
    opacity 0.3s,
    transform 0.3s,
    overlay 0.3s allow-discrete,
    display 0.3s allow-discrete;
}
@starting-style {
  .action-dialog[open] {
    opacity: 0;
  }
}
</style>
