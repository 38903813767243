import { computed, ref, watch } from "vue";
import { defineStore } from "pinia";
import { format } from "date-fns";
import { stringify } from "qs";
import { useFetch, useLocale } from "@/composables";
import { parseStringToJSON, formatSecondsToHHMMSS } from "@/utils/misc";
import { useWebSessionStore } from ".";

import { type Course } from "@/types/course";
import type {
  CourseReportDetailsResponse,
  CourseContainerReportDetails,
} from "@/types/reports";
import type { CourseDetails } from "@/types/course";
import { NODE_TYPE_IDS } from "@/constants/course";

type FetchCourseDetailsInput = {
  courseIds: number[];
  startDate: Date;
  endDate: Date;
  query?: string;
};

export const useCourseStore = defineStore("course", () => {
  const courses = ref<Course[]>([]);
  const coursesDetails = ref<CourseDetails[]>([]);
  const courseDetailsReport = ref<CourseContainerReportDetails | null>(null);

  const { locale } = useLocale();
  const { webSession, getLanguageRegionByLanguageCode } = useWebSessionStore();

  const exportableCourses = computed(() => {
    const AdminCourseId = 59684;
    return courses.value.filter(
      (course) =>
        course.PublicationStatus === 1 &&
        course.NodeTypeId === NODE_TYPE_IDS.COURSE &&
        course.CourseId !== AdminCourseId,
    );
  });

  const {
    data: coursesListResponse,
    isFetching: isFetchingCourses,
    error: coursesError,
  } = useFetch(`/api/CourseList/0`).json<Course[]>();

  const {
    data: coursesDetailsResponse,
    isFetching: isFetchingCoursesDetails,
    error: coursesDetailsError,
    execute: fetchCoursesDetails,
  } = useFetch(`/api/CourseData`, {
    immediate: false,
  }).json<CourseDetails[]>();

  watch([coursesListResponse, coursesDetailsResponse], ([fetchedCourses]) => {
    if (Array.isArray(fetchedCourses)) {
      courses.value = fetchedCourses.map((course) => ({
        ...course,
        courseLink: getCourseLink(
          course.CourseId,
          course.CourseName,
          course.Lang,
        ),
        properties: {
          ...course.properties,
          coursedescription_new: parseCourseDescription(
            course.properties.coursedescription_new,
          ),
        },
      }));
    }

    if (coursesDetailsResponse.value) {
      coursesDetails.value = coursesDetailsResponse.value;
    }
  });

  const getCourseLink = (
    courseId: number,
    courseName: string,
    courseLang: string,
  ) => {
    return `https://my.goodhabitz.com/${getLanguageRegionByLanguageCode(
      courseLang,
    )}/courses/${courseId}-${courseName.split(".html")[0]}`;
  };

  const getCategoryOptions = (courses: Course[]) => {
    const uniqueCategoryIds = [
      ...new Set(courses.map((item) => item.categoryId)),
    ];
    return uniqueCategoryIds.map((cId) => {
      const categoryTranslation = getCategoryName(cId);
      return { label: categoryTranslation, value: cId };
    });
  };

  const getCategoryName = (categoryId: number) => {
    const category = webSession?.CourseCategories.find(
      (cc) => categoryId === cc.Id,
    );
    let translatedCategoryName: string;
    if (category) {
      translatedCategoryName =
        parseStringToJSON(category.Value || "")?.[locale.value] ??
        category.Name ??
        "Unknown category";
    }
    return category ? translatedCategoryName : "Unknown category";
  };

  const getCourseGuid = (courseId: number) =>
    courses.value.length
      ? courses.value.find((course) => course.CourseId === courseId)?.CourseGuid
      : "";

  const getCourseLangById = (courseId: number) =>
    courses.value.find((course) => {
      return course.CourseId === courseId;
    })?.Lang;

  const formatReport = async ({ containerId, ...rest }: FormatReportInput) => {
    const coursesInContainer = courses.value.filter(
      (c) => c.CourseContainerId === containerId,
    );

    const { data: relatedReports } = await useFetch(
      `/api/ReportCourseDetail?${stringify(
        {
          CourseIds: coursesInContainer.map((course) => course.CourseId),
          DateFrom: format(rest.startDate, "yyyy-MM-dd"),
          DateTo: format(rest.endDate, "yyyy-MM-dd"),
          SearchFor: rest.query,
          Tags: [],
        },
        { encode: false, indices: false },
      )}`,
    ).json<CourseReportDetailsResponse[]>();

    const filteredRelatedReports =
      (relatedReports?.value as Array<CourseReportDetailsResponse>)
        ?.filter(
          (report): report is CourseReportDetailsResponse => report !== null,
        )
        .map((report) => {
          const courseLang = getCourseLangById(report.CourseId);
          return {
            ...report,
            language: courseLang,
            PersonDetails: report.PersonDetails.map((pd) => ({
              ...pd,
              language: courseLang,
            })),
          };
        }) ?? [];

    const combinedData = filteredRelatedReports.reduce(
      (acc, curr) => {
        return {
          ContentSeconds: acc.ContentSeconds + curr.ContentSeconds,
          CoursesCompleted: acc.CoursesCompleted + curr.CoursesCompleted,
          CoursesStarted: acc.CoursesStarted + curr.CoursesStarted,
          ModuleCount: acc.ModuleCount.concat(curr.ModuleCount),
          PersonDetails: acc.PersonDetails.concat(curr.PersonDetails),
          TestsPassed: acc.TestsPassed + curr.TestsPassed,
          ContentMinutes: acc.ContentMinutes + curr.ContentMinutes,
        };
      },
      {
        ContentMinutes: 0,
        ContentSeconds: 0,
        CoursesCompleted: 0,
        CoursesStarted: 0,
        ModuleCount: [],
        PersonDetails: [],
        TestsPassed: 0,
      },
    );
    const courseStudentsPerLanguages = getStudentsPerCourseLanguages(
      filteredRelatedReports,
    );

    const { courseName, courseDescription } = getReportNameAndDescription({
      courses: coursesInContainer,
      locale: locale.value,
    });

    courseDetailsReport.value = {
      courseName,
      courseDescription,
      totalStudentsCount: calculateTotalStudentsCount(
        courseStudentsPerLanguages,
      ),
      newStudentsCount: combinedData.CoursesStarted,
      courseCertificatesCount: combinedData.CoursesCompleted,
      testCertificatesCount: combinedData.TestsPassed,
      studyTime: formatSecondsToHHMMSS(combinedData.ContentSeconds),
      activityVisits: formatActivityVisits(combinedData.ModuleCount),
      studentsProgress: formatPersonDetails(combinedData.PersonDetails),
      courseStudentsPerLanguages,
    };
  };

  return {
    courses,
    exportableCourses,
    courseDetailsReport,
    isFetching: computed(
      () => isFetchingCourses.value || isFetchingCoursesDetails.value,
    ),
    error: computed(() => coursesError.value || coursesDetailsError.value),
    getCourseLink,
    getCategoryOptions,
    getCategoryName,
    getCourseGuid,
    formatReport,
    coursesDetails,
    fetchCoursesDetails,
  };
});

const formatPersonDetails = (
  personDetails: CourseReportDetailsResponse["PersonDetails"],
): CourseContainerReportDetails["studentsProgress"] => {
  return personDetails.map((pd) => ({
    name: `${pd.LastName}, ${pd.FirstName}`,
    foreignUsername: pd.ForeignUsername,
    email: pd.Email,
    language: pd.language ?? "N/A",
    certificateProgress: {
      value: pd.Progress,
      isComplete: pd.Completed,
    },
    studyTime: formatSecondsToHHMMSS(pd.ContentSeconds),
    testCertificateStatus: pd.Passed
      ? "passed"
      : pd.Completed
        ? "failed"
        : "notStarted",
  }));
};

const formatActivityVisits = (
  params: CourseReportDetailsResponse["ModuleCount"],
): CourseContainerReportDetails["activityVisits"] => {
  return params
    .map((mc) => ({
      date: new Date(mc.yyyy, mc.mm - 1, mc.dd),
      count: mc.modules,
    }))
    .sort((a, b) => a.date.getTime() - b.date.getTime());
};

type FormatReportInput = Omit<FetchCourseDetailsInput, "courseIds"> & {
  containerId: number;
};

const getStudentsPerCourseLanguages = (
  reports: Array<CourseReportDetailsResponse & { language: string }>,
) => {
  const result = [];

  for (const report of reports) {
    const studentsCount = report.PersonDetails.filter(
      (person) => person.ContentSeconds > 0,
    ).length;

    result.push({
      language: report.language,
      count: studentsCount,
    });
  }

  return result.sort((a, b) => b.count - a.count);
};

const calculateTotalStudentsCount = (
  courseStudentsPerLanguages: CourseContainerReportDetails["courseStudentsPerLanguages"],
) => {
  return courseStudentsPerLanguages.reduce((acc, curr) => acc + curr.count, 0);
};

const parseCourseDescription = (courseDescription: string) => {
  try {
    const parsedDescription = JSON.parse(courseDescription) as {
      [key: string]: string;
    };
    return Object.values(parsedDescription)[0];
  } catch (error) {
    return courseDescription;
  }
};

const getReportNameAndDescription = ({
  courses,
  locale,
}: {
  courses: Course[];
  locale: string;
}) => {
  const userLocaleCourse = courses.find(
    (course) =>
      course.Lang.toLocaleLowerCase() === locale.split("-")[0].toLowerCase(),
  );
  const EnglishCourse = courses.find((course) => course.Lang === "EN");
  const course = userLocaleCourse ?? EnglishCourse ?? courses[0];

  return {
    courseName: course.properties.title || "",
    courseDescription: course.properties.coursedescription_new || "",
  };
};
