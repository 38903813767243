<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { GButton, GModal, TextInput } from "@/components";
import useUserActionsStore from "../store";
import { useTeamsStore } from "@/stores";

import SearchIcon from "@/assets/images/icons/search.svg";
import PlusIcon from "@/assets/images/icons/plus.svg";

const emit = defineEmits<{
  (event: "close"): void;
}>();
const { t } = useI18n();
const userActionsStore = useUserActionsStore();
const { activeAction } = storeToRefs(userActionsStore);
const { closeModal } = userActionsStore;
const teamsStore = useTeamsStore();
const { teamsList } = storeToRefs(teamsStore);

const searchQuery = ref("");

const filteredTeams = computed(() => {
  return teamsList.value?.filter((team) =>
    team.Name.toLowerCase().includes(searchQuery.value.toLowerCase()),
  );
});

const selectedTeams = ref<number[]>([]);
</script>
<template>
  <GModal
    :isOpen="activeAction === 'addToTeam'"
    scrollBehaviour="modal"
    :title="t('organisation.add_to_team')"
    @close="closeModal()"
    data-testId="add-to-team-modal"
  >
    <!-- eslint-disable vue/no-bare-strings-in-template -->
    <span class="min-w-64 text-xl text-red-500">Coming soon</span>
    <p>{{ t("organisation.modal_add_to_team_description") }}</p>
    <TextInput
      name="title"
      fullWidth
      :labelClasses="['hidden']"
      :placeholder="`${t('common.search')}...`"
      :label="t('common.search')"
      v-model="searchQuery"
    >
      <template #prefixIcon><SearchIcon /></template>
    </TextInput>
    <span id="teams-list-label">{{ t("common.select_teams") }}</span>
    <ul
      aria-labelledby="teams-list-label"
      class="block h-[40vh] overflow-y-auto lg:w-[40rem]"
    >
      <li
        v-for="team in filteredTeams"
        :key="team.Id"
        class="flex items-center gap-2 rounded-lg p-4 hover:bg-slate-100"
      >
        <input
          type="checkbox"
          class="checkbox checkbox-primary"
          :value="team.Id"
          v-model="selectedTeams"
        />{{ team.Name }}
        <span class="ml-auto">{{
          t("organisation.team_members_count", { count: team.ActiveUserCount })
        }}</span>
      </li>
    </ul>

    <template #actions>
      <GButton variant="tertiary" @click="() => emit('close')">
        <template #prefixIcon><PlusIcon /></template>
        {{ t("organisation.new_team") }}</GButton
      >
      <GButton
        @click="() => emit('close')"
        :disabled="true || selectedTeams.length === 0"
        >{{ t("organisation.add_to_team") }}</GButton
      >
    </template>
  </GModal>
</template>
