<script setup lang="ts">
import { GToggleBar, PageLayout, GEmailTagTextarea } from "@/components";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

// TODO: Remove before merge to main
const emails = ref<string[]>([]);

const handleError = (errors: Array<{ type: string; path: number[] }>) => {
  console.log(errors);
};

watch(emails, (value) => {
  console.log(value);
});
</script>
<template>
  <GToggleBar :label="t('common.toggle_bar_label')" opt="in" />
  <PageLayout :title="t('overview.title')">
    <div class="h-48">
      <GEmailTagTextarea
        v-model="emails"
        :placeholder="t('organisation.invite_users_textarea_placeholder')"
        @error="handleError"
      />
    </div>
  </PageLayout>
</template>
