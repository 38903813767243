import { z, ZodIssueCode, type ZodIssue } from "zod";

type EmailErrorType = "invalid" | "duplicate";

const MAX_EMAIL_LENGTH = 254;
const emailSchema = z.string().email().max(MAX_EMAIL_LENGTH);

export const isValidEmail = (email: string) => {
  return emailSchema.safeParse(email).success;
};

export const validateUniqueEmailList = (emails: string[]) => {
  const emailListSchema = z.array(emailSchema).superRefine((emails, ctx) => {
    const uniqueValues = new Map<string, number>();
    emails.forEach((email, idx) => {
      const firstAppearanceIndex = uniqueValues.get(email);
      if (firstAppearanceIndex !== undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Value must be unique`,
          path: [idx],
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Value must be unique`,
          path: [firstAppearanceIndex],
        });
        return;
      }
      uniqueValues.set(email, idx);
    });
  });

  return emailListSchema.safeParse(emails);
};

export const getEmailErrorType = (issue: ZodIssue): EmailErrorType => {
  const issueCodeToErrorType: Partial<Record<ZodIssueCode, EmailErrorType>> = {
    custom: "duplicate",
    too_big: "invalid",
    invalid_type: "invalid",
  };

  return issueCodeToErrorType[issue.code] ?? "invalid";
};
