<script setup lang="ts">
import { GButton, GModal } from "@/components";
import { useI18n } from "vue-i18n";
import useUserActionsStore from "../store";
import { storeToRefs } from "pinia";

const { t } = useI18n();
const userActionsStore = useUserActionsStore();
const { activeAction, isActionRequestPending } = storeToRefs(userActionsStore);
const { closeModal } = userActionsStore;
</script>
<template>
  <GModal
    :isOpen="activeAction === 'resendInvitation'"
    :title="t('organisation.resend_invitation')"
    @close="closeModal"
  >
    <!-- eslint-disable vue/no-bare-strings-in-template -->
    <span class="min-w-64">Coming soon</span>
    <!-- {{ t("organisation.modal_resend_invitation_description") }} -->
    <template #actions>
      <GButton variant="link" @click="closeModal">{{
        t("common.cancel")
      }}</GButton>
      <GButton
        :isLoading="isActionRequestPending"
        @click="() => {}"
        :disabled="true"
        >{{ t("organisation.revoke") }}</GButton
      >
    </template>
  </GModal>
</template>
