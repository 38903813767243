<script setup lang="ts">
import { ref, watchEffect } from "vue";
import { ldInit } from "launchdarkly-vue-client-sdk";
import type { LoggedInUser } from "../types/websession";
import { useEntitlementsStore, useUiStore } from "@/stores";
import { useLocale } from "@/composables";

const props = defineProps<{ user: LoggedInUser }>();
const { locale } = useLocale();
const [isLDReady] = ldInit({
  context: {
    kind: "multi",
    user: {
      key: props.user.ExternalPersonReference,
      country: props.user.Country ?? "",
      language: locale.value ?? "",
      loginOrigin: props.user.LoginOrigin ?? "",
      // --- Start: Temporary until flags and segments are updated ---
      company: props.user.CompanyName,
      companyId: props.user.ExternalCompanyReference,
      isTrialAccount: props.user.CompanyTypeId === 7 ? true : false,
      // --- End: Temporary until flags and segments are updated ---
    },
    company: {
      key: props.user.ExternalCompanyReference,
      name: props.user.CompanyName,
      isTrialAccount: props.user.CompanyTypeId === 7 ? true : false,
    },
    // --- Start: Temporary until all LTI1.3 customers migrated to lms-connector ---
    companyId: {
      key: props.user.CompanyId.toString(),
      kind: "companyId",
    },
    // --- End: Temporary until all LTI1.3 customers migrated to lms-connector ---
  },
});

const uiStore = useUiStore();

const isMinWaitOver = ref(false);
setTimeout(() => {
  isMinWaitOver.value = true;
}, 400);

watchEffect(() => {
  if (isMinWaitOver.value && isLDReady.value) {
    uiStore.setIsLoading(false);
    useEntitlementsStore();
  }
});
</script>
<template>
  <slot v-if="isLDReady && isMinWaitOver"></slot>
</template>
