import { useI18n } from "vue-i18n";
import {
  useNotificationsStore,
  useUserStore,
  useWebSessionStore,
} from "@/stores";
import {
  CONTENT_MODE,
  RemoteScormContentType,
  type ContentRemoteScorm,
  type ContentRS,
  type ContentRSPayload,
  type ContentType,
  type MultiLanguageBundledContentRS,
  type RemoteScormExportRequest,
  type TemplateAttributes,
} from "@/types/export";
import {
  useLMSConnectorFetch,
  useWebCatalogFetch,
} from "@/composables/useFetch";
import saveAs from "file-saver";

export const defaultTemplateAttributes: TemplateAttributes = {
  mixedTrainingResultComplete: "completed",
  mode: 0,
  mixedTrainingResultIncomplete: "incomplete",
  threshold: 100,
};

export const isMultiLanguageBundledContent = (
  content: ContentRS | MultiLanguageBundledContentRS,
): content is MultiLanguageBundledContentRS => {
  return (
    (content as MultiLanguageBundledContentRS).contentListInContainer !==
    undefined
  );
};

const getLanguageOptions = (contentList: ContentRS[]) => {
  const webSessionStore = useWebSessionStore();
  const { languageOptions } = webSessionStore;
  return languageOptions
    .filter((lang) => {
      return contentList.some((content) => {
        return content.language === lang.value;
      });
    })
    .map((item) => ({ lang: item.value, label: item.label }));
};

const getPackageName = (
  content: ContentRS | MultiLanguageBundledContentRS,
  contentType: ContentType,
  mode: number,
) => {
  const isMultiLang = isMultiLanguageBundledContent(content);
  let fileName: string = contentType;

  fileName += isMultiLang
    ? `-${content.containerName.trim().toLowerCase()}`
    : `-${content.name.trim().toLowerCase()}`;

  if (contentType === "COURSE") {
    fileName += `-${CONTENT_MODE[mode]}`;
  } else if (!isMultiLang && contentType !== "GOODSCAN")
    fileName += `-${content.language?.toLowerCase() || content.locale?.toLowerCase()}`;

  fileName = removeSpecialCharactersAndAccents(fileName);
  fileName += ".zip";

  return fileName;
};

const getRemoteScormPackageFileName = (request: RemoteScormExportRequest) => {
  const { getLanguageCodeByLanguageRegion } = useWebSessionStore();
  let fileName: string = RemoteScormContentType[request.contentType];

  fileName += request.isMultipleLanguage
    ? `-${request.containerName.trim().toLowerCase()}`
    : `-${request.title.trim().toLowerCase()}`;

  if (request.contentType === RemoteScormContentType.Course) {
    fileName += `-${CONTENT_MODE[request.mode]}`;
  } else if (
    !request.isMultipleLanguage &&
    request.contentType !== RemoteScormContentType.GoodScan
  )
    fileName += `-${getLanguageCodeByLanguageRegion(request.locale).toLowerCase()}`;

  fileName = removeSpecialCharactersAndAccents(fileName);
  fileName += ".zip";
  return fileName;
};

const formatLanguageRegion = (languageRegion: string) => {
  const [langCode, countryCode] = languageRegion.split("-");
  return `${langCode}-${countryCode.toUpperCase()}`;
};

export const removeSpecialCharactersAndAccents = (s: string): string => {
  const normalized = s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  const cleaned = normalized
    .replace(/[^a-zA-Z0-9 \u4e00-\u9fa5\u0400-\u04FF-]/g, "")
    .replace(/\s+/g, "-");

  return cleaned;
};

const downloadRSPackage = (contentData: ContentRSPayload) => {
  const fetch = useWebCatalogFetch("/api/export-scorm/content", {
    immediate: false,
  })
    .post(contentData)
    .blob();

  const title = isMultiLanguageBundledContent(contentData.content)
    ? contentData.content?.containerName
    : contentData.content?.title;

  return {
    fetch,
    title,
    fileName: getPackageName(
      contentData.content,
      contentData.contentType,
      contentData.templateAttributes.mode,
    ),
  };
};

const downloadRemoteScormPackage = (request: RemoteScormExportRequest) => {
  const {
    contentId,
    contentType,
    companyId,
    mixedTrainingResultComplete,
    mixedTrainingResultIncomplete,
    threshold,
    mode,
    isMultipleLanguage,
    title,
    locale,
  } = request;
  const fetch = useLMSConnectorFetch("/export-scorm/content", {
    immediate: false,
  })
    .post({
      contentId,
      contentType,
      companyId,
      mixedTrainingResultComplete,
      mixedTrainingResultIncomplete,
      threshold,
      mode,
      isMultipleLanguage,
      title,
      locale,
    })
    .blob();
  return fetch;
};

export const useDownloadScorm = () => {
  const userStore = useUserStore();
  const companyId = userStore.user?.CompanyId ?? 0;

  const { t } = useI18n();
  const notificationsStore = useNotificationsStore();

  const downloadScormPackages = async (
    contentType: ContentType,
    contentList: (ContentRS | MultiLanguageBundledContentRS)[],
    templateAttributes?: TemplateAttributes,
  ) => {
    const batchSize = 10;
    const delay = 1000;
    const erroredTitles: string[] = [];

    const promises = contentList.map((content) => {
      const payload: ContentRSPayload = {
        content,
        templateAttributes: templateAttributes ?? defaultTemplateAttributes,
        companyId: companyId,
        contentType,
      };
      return downloadRSPackage(payload);
    });

    /**
     * Process promises in batches with a delay to avoid server overload
     * and allow browsers to handle files to be downloaded properly
     */
    for (let i = 0; i < promises.length; i += batchSize) {
      const currentBatch = promises.slice(i, i + batchSize);
      await Promise.all(currentBatch.map((command) => command.fetch.execute()));
      currentBatch.forEach((promise) => {
        if (
          promise.fetch.statusCode.value >= 200 &&
          promise.fetch.statusCode.value <= 300
        )
          saveAs(promise.fetch.data.value, promise.fileName);
        else erroredTitles.push(promise.title);
      });

      await new Promise((resolve) => setTimeout(resolve, delay));
    }
    if (erroredTitles.length > 0)
      notificationsStore.showNotification({
        message: `${t("error.could_not_download")}: ${erroredTitles.join(", \n\n")}`,
        type: "danger",
      });
  };

  const downloadRemoteScormPackages = async (
    contentList: ContentRemoteScorm[],
    templateAttributes?: TemplateAttributes,
  ) => {
    const batchSize = 10;
    const delay = 1000;
    const erroredTitles: string[] = [];

    const promises = contentList.map((content) => {
      const templateAttributeWithDefault =
        templateAttributes ?? defaultTemplateAttributes;
      const reqeust: RemoteScormExportRequest = {
        contentId: content.contentId,
        contentType: content.contentType,
        isMultipleLanguage: content.isMultipleLanguage,
        title: content.title,
        locale: formatLanguageRegion(content.locale),
        ...templateAttributeWithDefault,
        companyId: companyId,
        containerName: content.containerName,
      };
      return {
        fetch: downloadRemoteScormPackage(reqeust),
        title: content.title,
        fileName: getRemoteScormPackageFileName(reqeust),
      };
    });

    /**
     * Process promises in batches with a delay to avoid server overload
     * and allow browsers to handle files to be downloaded properly
     */
    for (let i = 0; i < promises.length; i += batchSize) {
      const currentBatch = promises.slice(i, i + batchSize);
      await Promise.all(currentBatch.map((command) => command.fetch.execute()));
      currentBatch.forEach((promise) => {
        if (
          promise.fetch.statusCode.value >= 200 &&
          promise.fetch.statusCode.value <= 300
        )
          saveAs(promise.fetch.data.value, promise.fileName);
        else erroredTitles.push(promise.title);
      });

      await new Promise((resolve) => setTimeout(resolve, delay));
    }
    if (erroredTitles.length > 0)
      notificationsStore.showNotification({
        message: `${t("error.could_not_download")}: ${erroredTitles.join(", \n\n")}`,
        type: "danger",
      });
  };

  return {
    getLanguageOptions,
    downloadScormPackages,
    downloadRemoteScormPackages,
  };
};
