<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  GButton,
  GStateHandlerWrapper,
  GExportModal,
  GDatePicker,
  GNameCard,
} from "@/components";
import { FilterPanel, TeamsSelect, GTable } from "@/patterns";
import { useDetailedUserReport } from "./useDetailedUserReport";
import type { DetailedUserTableRow, Filters } from "./types";
import { reportInitialDateRange } from "../utils";
import { formatDataForExport } from "@/utils/export";
import { useFlagStore } from "@/stores";

import ArrowDownToLineIcon from "@/assets/images/icons/arrow-down-to-line.svg";

const initialFilters: Filters = {
  search: "",
  userGroups: [],
  date: reportInitialDateRange,
};

const flagStore = useFlagStore();
const filters = ref<Filters>({ ...initialFilters });

const { t } = useI18n();
const selectedRows = ref<DetailedUserTableRow[]>([]);
const { data, isFetching, error, tableDefinition, hasForeignUsername } =
  useDetailedUserReport(filters);

const isExportModalOpen = ref(false);

const dataToExport = computed(() =>
  formatDataForExport({
    columns: [
      {
        accessor: "name",
        header: t("common.name"),
      },
      {
        accessor: "email",
        header: t("reports.tbl_col_email"),
      },
      {
        accessor: "courseName",
        header: t("reports.tbl_col_course_title"),
      },
      {
        accessor: "lessonName",
        header: t("detailedUser.tbl_col_lesson"),
      },
      {
        accessor: "activity",
        header: t("detailedUser.tbl_col_activity"),
      },
      {
        accessor: "progress",
        header: t("reports.tbl_col_progress"),
      },
      {
        accessor: "sessionTime",
        header: t("detailedUser.tbl_col_session_time"),
      },
      {
        accessor: "date",
        header: t("reports.tbl_col_date"),
      },
      {
        accessor: "foreignUsername",
        header: t("reports.tbl_col_foreign_username"),
        condition: hasForeignUsername.value,
      },
    ],
    data: selectedRows.value,
  }),
);
</script>
<template>
  <GButton
    v-if="!flagStore.actionBarFlag"
    class="mb-4 ml-auto lg:px-12"
    @click="isExportModalOpen = true"
    :disabled="selectedRows.length === 0"
    data-testid="export-detailed-users-btn"
  >
    <template #prefixIcon><ArrowDownToLineIcon /></template>
    {{ t("common.export") }}
  </GButton>
  <FilterPanel
    v-model:search="filters.search"
    @reset="filters = { ...initialFilters }"
  >
    <template #primaryFilters>
      <TeamsSelect v-model="filters.userGroups" />
      <GDatePicker v-model="filters.date" :label="t('common.select_date')" />
    </template>
  </FilterPanel>
  <GStateHandlerWrapper :isLoading="isFetching" :error="error">
    <GTable
      :data="data"
      :columns="tableDefinition"
      :selectedList="selectedRows"
      @selectedListChange="
        (list) => selectedRows.splice(0, selectedRows.length, ...list)
      "
      :exportFn="() => (isExportModalOpen = true)"
    >
      <template v-slot:name="{ row }">
        <GNameCard
          :name="row.name"
          :fuid="row.foreignUsername"
          :email="row.email"
        />
      </template>
      <template v-slot:date="{ value }">
        {{ (value as Date)?.toLocaleDateString() }}
      </template>
    </GTable>
  </GStateHandlerWrapper>
  <GExportModal
    :isOpen="isExportModalOpen"
    fileName="GH - detailed users report"
    :data="dataToExport"
    @close="isExportModalOpen = false"
  />
</template>
