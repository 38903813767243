<script setup lang="ts">
import { ListboxButton } from "@headlessui/vue";

import ChevronDownIcon from "../../assets/images/icons/chevron-down.svg";

type DropdownIconButtonTriggerProps = {
  icon?: string | object;
  variant?: "primary" | "secondary";
};

const props = withDefaults(defineProps<DropdownIconButtonTriggerProps>(), {
  icon: ChevronDownIcon,
  variant: "primary",
});

const variantClasses: Record<
  NonNullable<DropdownIconButtonTriggerProps["variant"]>,
  string
> = {
  primary:
    "border border-transparent bg-white text-primary hover:border-primary focus:border-primary fill-primary",
  secondary:
    "bg-transparent text-white fill-white hover:bg-[#3F15A5] focus:border-white fill-white",
};
</script>
<template>
  <ListboxButton
    class="grid place-content-center rounded-lg px-4 py-[0.64rem] text-start"
    :class="[{ 'border-primary': false }, variantClasses[variant]]"
  >
    <component :is="props.icon" />
  </ListboxButton>
</template>
