<script setup lang="ts" generic="TData extends {}">
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import {
  getCoreRowModel,
  useVueTable,
  createColumnHelper,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
  FlexRender,
  type ExpandedState,
  type SortingState,
  type RowSelectionState,
  type ColumnDef,
  type VisibilityState,
  type ColumnFilter,
  type FilterFnOption,
  type Row,
} from "@tanstack/vue-table";
import { storeToRefs } from "pinia";
import { trackMixPanelEvent } from "@/services/analytics/mixpanel";
import { fuzzyFilter, includesLocaleString } from "@/composables/useFilter";
import { ActionBar } from "@/components";
import { useFlagStore, useUiStore } from "@/stores";

import ColumnSort from "./ColumnSort.vue";
import EmptyTableView from "./EmptyTableView.vue";
import TablePaginationControl from "./TablePaginationControl.vue";

import ChevronDownIcon from "@/assets/images/icons/chevron-down.svg";

/**
 * Column type definition
 * @propery id: required because accessor is not available on 'display cells' like checkbox or button
 * @property accessor: defines what property of the data item should be rendered in the column
 * (should be keyof TData, but because it's inferred from props.data, it is not known at this point)
 * @property headerLabel: Label for column header
 * @property visibleFrom: Show column on this breakpoint and above - if false, column is hidden
 * @property filterFnName: Name of the filter function to be used for this column
 * @property sortable: Enable sorting for this column, default is true
 * @property smallScreenPosition: Show this column as main info on small screens
 * @property isGloballyFilterable: Enable search for this column
 */
export type TableColumn<T = any> = {
  id: string;
  accessor?: T;
  headerLabel?: string;
  visibleFrom?: "sm" | "md" | "lg" | boolean;
  filterFnName?: FilterFnOption<any>;
  sortable?: boolean;
  smallScreenPosition?: "first" | "center" | "last";
  isGloballyFilterable?: boolean;
};

export type ActionOption = {
  id: string;
  label: string;
  action: () => void;
  isDestructive?: boolean;
};

const props = withDefaults(
  defineProps<{
    data: TData[];
    columns: TableColumn[];
    filter?: {
      global?: string;
      columns?: ColumnFilter[];
    };
    defaultSort?: SortingState;
    selectable?: "rows" | "subRows" | boolean;
    selectedList?: TData[];
    isExpanded?: boolean;
    actions?: ActionOption[];
    exportFn?: () => void;
  }>(),
  {
    selectable: true,
  },
);

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits<{
  (e: "selectedListChange", value: TData[]): void;
}>();

const { t } = useI18n();
const route = useRoute();
const uiStore = useUiStore();
const flagStore = useFlagStore();
const { tableRef } = storeToRefs(uiStore);

const actionsWithExport = computed(() => {
  const listOfActions = [];
  const exportAction = props.exportFn
    ? {
        id: "export",
        label: t("common.export"),
        action: props.exportFn,
      }
    : undefined;
  if (props.actions) {
    listOfActions.push(...props.actions);
  }
  if (exportAction) {
    listOfActions.push(exportAction);
  }
  return listOfActions;
});

const columnHelper = createColumnHelper<TData>();

const mappedColumns: ColumnDef<TData, any>[] = props.columns.map((col) => {
  if (col.accessor) {
    return columnHelper.accessor(col.accessor as any, {
      id: col.id,
      header: col.headerLabel,
      enableColumnFilter: !!col.filterFnName,
      enableGlobalFilter: col.isGloballyFilterable,
      enableSorting: col.sortable !== false,
      filterFn: col.filterFnName || includesLocaleString,
      meta: {
        smallScreenPosition: col.smallScreenPosition,
        visibleFrom: col.visibleFrom,
      },
    });
  }
  return columnHelper.display({
    id: col.id,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    header: col.headerLabel,
    meta: {
      smallScreenPosition: col.smallScreenPosition,
    },
  });
});

const sorting = ref<SortingState>(props.defaultSort || []);

const columnVisibility = ref<VisibilityState>({});
const expanded = ref<ExpandedState>({});

const rowSelection = ref<RowSelectionState>({});

watch(
  () => props.selectedList,
  (newVal) => {
    if (newVal?.length === 0) table.resetRowSelection();
  },
  { deep: true },
);

watch(rowSelection, () => {
  const selectedRows = table.getSelectedRowModel().rows;
  emit(
    "selectedListChange",
    selectedRows.map((row) => row.original) as TData[],
  );
});

const getColVisibilityClasses = (visibleFrom: string | boolean) => {
  if (visibleFrom === undefined) return;
  return (
    `small-screen-row-hidden !hidden` +
    (visibleFrom === false ? "" : `${visibleFrom}:block`)
  );
};

const getSmallScreenPositionClasses = (position: string | undefined) => {
  if (position === undefined) return;
  return `row-start-1 ${
    position === "first"
      ? "col-start-1"
      : position === "center"
        ? "col-start-2 font-bold md:font-normal"
        : "col-start-3"
  }`;
};

const table = useVueTable({
  get data() {
    return props.data;
  },
  columns: mappedColumns,
  filterFns: {
    fuzzy: fuzzyFilter,
  },
  state: {
    get rowSelection() {
      return rowSelection.value;
    },
    get sorting() {
      return sorting.value;
    },
    get columnVisibility() {
      return columnVisibility.value;
    },
    get globalFilter() {
      return props.filter?.global;
    },
    get columnFilters() {
      return props.filter?.columns;
    },
    get expanded() {
      return expanded.value;
    },
  },
  onColumnVisibilityChange: (updaterOrValue) => {
    columnVisibility.value =
      typeof updaterOrValue === "function"
        ? updaterOrValue(columnVisibility.value)
        : updaterOrValue;
  },
  onRowSelectionChange: (updaterOrValue) => {
    rowSelection.value =
      typeof updaterOrValue === "function"
        ? updaterOrValue(rowSelection.value)
        : updaterOrValue;
  },
  onSortingChange: (updaterOrValue) => {
    sorting.value =
      typeof updaterOrValue === "function"
        ? updaterOrValue(sorting.value)
        : updaterOrValue;
    trackMixPanelEvent("sort", {
      context: "Dashboard",
      page: route?.path,
      sort: sorting.value,
    });
  },
  onExpandedChange: (updaterOrValue) => {
    expanded.value =
      typeof updaterOrValue === "function"
        ? updaterOrValue(expanded.value)
        : updaterOrValue;
  },
  getSubRows: (row) => row.subRows,
  globalFilterFn: fuzzyFilter,
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
  getPaginationRowModel: getPaginationRowModel(),
  getExpandedRowModel: getExpandedRowModel(),
  filterFromLeafRows: true,
  paginateExpandedRows: false,
  enableMultiRowSelection: true,
});

table.toggleAllRowsExpanded(props.isExpanded);

const toggleRowExpand = (row: Row<TData>) => {
  row.getToggleExpandedHandler()?.();
  trackMixPanelEvent("expand", {
    context: "Dashboard",
    page: route?.path,
    item: (row.original as any).title ?? (row.original as any).name,
  });
};
</script>

<template>
  <div class="my-4 overflow-x-auto">
    <table class="w-full" v-bind="$attrs" ref="tableRef">
      <thead class="sr-only md:not-sr-only">
        <tr>
          <th v-if="table.getCanSomeRowsExpand()" class="text-transparent"></th>
          <th v-if="selectable" class="w-10">
            <input
              type="checkbox"
              class="checkbox checkbox-primary checkbox-sm align-middle"
              :checked="table.getIsAllRowsSelected()"
              :indeterminate="table.getIsSomeRowsSelected()"
              @change="table.getToggleAllRowsSelectedHandler()?.($event)"
              :aria-label="t('table.select_all')"
            />
          </th>
          <th
            v-for="(header, i) in table.getFlatHeaders()"
            :key="i"
            :class="
              getColVisibilityClasses(header.column.columnDef.meta.visibleFrom)
            "
          >
            <div class="flex flex-nowrap items-center text-grey-70">
              <template v-if="!header.isPlaceholder">
                <span class="whitespace-pre-wrap">
                  <FlexRender
                    :render="header.column.columnDef.header"
                    :props="header.getContext()"
                /></span>
                <ColumnSort
                  v-if="header.column.getCanSort()"
                  @click="header.column.getToggleSortingHandler()?.($event)"
                  :sortedState="header.column.getIsSorted()"
                />
              </template>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in table.getRowModel().rows"
          :key="row.id"
          class="md:group grid grid-cols-[min-content_1fr_min-content] border px-2 py-2 align-baseline first:rounded-t-lg last:rounded-b-lg md:table-row md:py-0 [&:not(:last-child)]:border-b-0"
        >
          <td v-if="table.getCanSomeRowsExpand()" class="w-10">
            <button v-if="row.getCanExpand()" @click="toggleRowExpand(row)">
              <ChevronDownIcon
                class="shrink-0"
                :class="row.getIsExpanded() ? '' : '-rotate-90'"
              />
            </button>
          </td>
          <td v-if="selectable" class="row-start-1">
            <input
              v-if="
                selectable === true ||
                (row.getCanExpand()
                  ? selectable === 'rows'
                  : selectable === 'subRows')
              "
              type="checkbox"
              class="checkbox checkbox-primary checkbox-sm align-middle"
              :checked="row.getIsSelected() || row.getIsAllSubRowsSelected()"
              :indeterminate="row.getIsSomeSelected()"
              @change="row.getToggleSelectedHandler()?.($event)"
              :aria-label="t('table.select_row')"
              data-testid="row-select"
            />
          </td>
          <td
            v-for="cell in row.getVisibleCells()"
            :key="cell.id"
            class="flex justify-between md:table-cell"
            :class="[
              getColVisibilityClasses(cell.column.columnDef.meta.visibleFrom),
              getSmallScreenPositionClasses(
                cell.column.columnDef.meta.smallScreenPosition,
              ) ?? 'small-screen-row col-span-3',
            ]"
          >
            <label
              class="mr-auto inline-block md:hidden"
              v-if="!cell.column.columnDef.meta.smallScreenPosition"
              >{{ cell.column.columnDef.header }}</label
            >
            <slot
              :name="cell.column.id"
              :value="cell.getValue()"
              :row="cell.row.original"
            >
              <FlexRender
                :render="cell.column.columnDef.cell"
                :props="cell.getContext()"
              />
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
    <ActionBar
      v-if="
        flagStore.actionBarFlag &&
        actionsWithExport &&
        actionsWithExport.length > 0
      "
      @deselectItems="() => table.toggleAllRowsSelected(false)"
      :selectedItems="selectedList"
      :actions="actionsWithExport"
    />
  </div>
  <EmptyTableView v-if="table.getRowModel().rows.length === 0" />
  <TablePaginationControl v-else :table="table" />
</template>
<style scoped lang="postcss">
th {
  @apply px-4 py-3 text-left;
}
td {
  @apply flex items-center bg-white p-2 align-middle group-hover:bg-grey-5 md:table-cell md:p-4;
}
.small-screen-row:nth-child(odd of :not(.small-screen-row-hidden)) {
  @apply bg-grey-5 md:bg-white;
}
</style>
