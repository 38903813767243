<script setup lang="ts">
import CloseIcon from "@/assets/images/icons/close.svg";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
type BaseProps = {
  text: string;
  variant?: "neutral" | "error";
  type?: "light" | "outline";
  disabled?: boolean;
  focused?: boolean;
  size?: "default" | "compact";
};

type PropsWithDismissible = BaseProps & {
  dismissible?: boolean;
  iconPosition?: "leading";
  icon?: string;
};

type PropsWithTrailingIcon = BaseProps & {
  iconPosition?: "trailing";
  icon?: string;
  dismissible?: never;
};

type Props = PropsWithDismissible | PropsWithTrailingIcon;

const props = withDefaults(defineProps<Props>(), {
  variant: "neutral",
  type: "light",
  size: "default",
});

const emit = defineEmits<{
  (e: "dismiss"): void;
}>();

const tagRef = ref<HTMLSpanElement | null>(null);

defineExpose({
  focus: () => {
    tagRef.value?.focus();
  },
});

const { t } = useI18n();

const variantClasses = {
  neutral:
    "bg-neutral-light text-neutral border border-grey-20 hover:text-white hover:bg-black focus:bg-black focus:text-white focus:outline-black",
  error: "bg-error-light text-error",
} as const;

const sizeClasses = {
  default: "py-1",
  compact: "py-2",
} as const;
</script>
<template>
  <span
    ref="tagRef"
    class="flex items-center gap-2 rounded-lg px-4 focus:outline focus:outline-offset-2"
    :class="[variantClasses[props.variant], sizeClasses[props.size]]"
    tabindex="0"
  >
    <component
      v-if="props.icon && props.iconPosition === 'leading'"
      :is="props.icon"
      aria-hidden="true"
      class="size-4"
    />
    <span v-if="props.text" class="text-sm font-medium">{{ props.text }}</span>
    <component
      v-if="props.icon && props.iconPosition === 'trailing'"
      :is="props.icon"
      aria-hidden="true"
      class="size-4"
    />
    <button
      v-if="props.dismissible"
      class="text-sm font-medium disabled:cursor-not-allowed"
      :disabled="props.disabled"
      :aria-label="t('common.dismiss')"
      @click="emit('dismiss')"
    >
      <CloseIcon aria-hidden="true" class="size-4 text-current" />
    </button>
  </span>
</template>
