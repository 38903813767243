<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useTitle } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { GAlert, GButton, GSpinner, GTooltip } from "@/components";
import {
  useEntitlementsStore,
  useNotificationsStore,
  useUiStore,
} from "@/stores";

import ArrowLeftIcon from "@/assets/images/icons/arrow-left.svg";
import { useRoute } from "vue-router";
import { computed } from "vue";
import EntitlementAccessDenied from "./EntitlementAccessDenied.vue";

type PageLayoutProps = {
  title: string;
  heading?: string;
  loading?: boolean;
  backButton?: { label: string; url: string };
  toolTipText?: string;
};

const props = defineProps<PageLayoutProps>();

const route = useRoute();
const { t } = useI18n();
const uiStore = useUiStore();
const { isMenuExpanded } = storeToRefs(uiStore);
useTitle(props.title);
const { checkEntitlementEnabled } = useEntitlementsStore();
const { notifications, persistedNotifications } = storeToRefs(
  useNotificationsStore(),
);
const { dismissNotification } = useNotificationsStore();

const isEntitled = computed(() => {
  if (!route.meta.flags.entitlements) return true;
  return route.meta.flags.entitlements?.every((entitlement) =>
    checkEntitlementEnabled(entitlement),
  );
});
</script>

<template>
  <main
    class="relative mb-10 w-full justify-self-center px-4 md:px-10 lg:max-w-screen-2xl"
  >
    <section class="py-4 text-black md:px-0 md:py-8">
      <div class="mt-4 flex justify-between">
        <GButton v-if="backButton" variant="link" :href="backButton.url">
          <template #prefixIcon>
            <ArrowLeftIcon class="fill-primary" />
          </template>
          {{ backButton.label }}
        </GButton>
        <GButton
          class="ml-auto lg:hidden"
          variant="tertiary"
          @click="() => uiStore.toggleDashboardMenu()"
        >
          <template #prefixIcon>
            <ArrowLeftIcon
              class="fill-primary"
              :class="isMenuExpanded ? 'rotate-90' : '-rotate-90'"
            /> </template
          >{{ t("common.dashboard_menu") }}</GButton
        >
      </div>
      <div
        class="flex flex-col items-start justify-between gap-4 lg:flex-row lg:items-center"
      >
        <div class="w-full">
          <slot name="heading">
            <h1 class="inline-block lg:w-2/3" data-testid="heading">
              <span class="flex items-center">
                {{ heading }}
                <GTooltip
                  v-if="toolTipText"
                  class="inline-block w-64 rounded-lg bg-gray-900 p-2 text-sm text-white shadow-lg"
                  placement="top-end"
                >
                  <template #content>
                    {{ toolTipText }}
                  </template>
                </GTooltip>
              </span>
            </h1>
          </slot>
        </div>
        <slot name="primaryAction"></slot>
      </div>
      <div v-if="persistedNotifications.length > 0">
        <GAlert
          v-for="notification in persistedNotifications"
          :key="notification.id"
          :variant="notification.type"
          :message="notification.message"
        />
      </div>
    </section>

    <div v-if="!isEntitled">
      <EntitlementAccessDenied />
    </div>
    <div v-else class="relative">
      <div
        v-if="loading"
        class="absolute inset-0 z-10 flex items-center justify-center"
      >
        <GSpinner />
      </div>
      <div v-if="loading" class="absolute inset-0 bg-gray-100 opacity-75"></div>
      <slot> </slot>
    </div>
    <TransitionGroup
      name="notifications"
      tag="div"
      class="absolute left-1/2 top-28 z-50 mb-2 w-3/4 -translate-x-1/2 rounded-lg"
    >
      <GAlert
        class="mb-2 shadow-md"
        v-for="notification in notifications"
        :key="notification.id"
        :variant="notification.type"
        :message="notification.message"
        @dismiss="dismissNotification(notification.id)"
      />
    </TransitionGroup>
  </main>
</template>
<style>
.notifications-enter-active {
  transition: all 0.5s ease-in-out;
}

.notifications-leave-active {
  transition: all 0.2s ease-in-out;
}

.notifications-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.notifications-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
