import common from "./common.json";
import reports from "./reports.json";
import assessments from "./assessments.json";
import navigation from "./navigation.json";
import contentSelection from "./content-selection.json";
import connectionMethods from "./connection-methods.json";
import organisation from "./organisation.json";
import resources from "./resources.json";
import aiConfig from "./ai-config.json";
import overview from "./overview.json";

export default {
  ...common,
  ...reports,
  ...assessments,
  ...navigation,
  ...contentSelection,
  ...connectionMethods,
  ...organisation,
  ...resources,
  ...aiConfig,
  ...overview,
};
