<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { GButton, GStateHandlerWrapper, GDatePicker } from "@/components";
import {
  FilterPanel,
  LanguageSelect,
  GTable,
  CourseCategoriesSelect,
} from "@/patterns";
import { useFlagStore } from "@/stores";
import { getLocaleWithUpperCaseRegion, type LanguageCode } from "@/utils/i18n";
import ExportModal from "./CoursesExportModal.vue";
import { type CourseTableDataRow, useCoursesTab } from "./useCoursesTab";

import ExternalLinkIcon from "@/assets/images/icons/external-link.svg";
import NoPreview from "@/assets/images/no-preview.svg?url";
import ArrowDownToLineIcon from "@/assets/images/icons/arrow-down-to-line.svg";

type Filters = {
  dateOfPublication: [Date, Date];
  categoryId: number[];
  language: LanguageCode[] | null[];
  search: string;
};

const initialFilters: Filters = {
  dateOfPublication: [new Date("2012-01-01"), new Date()],
  categoryId: [],
  language: [null],
  search: "",
};

const flagStore = useFlagStore();
const { t } = useI18n();

const {
  coursesTableDefinition,
  courseList,
  isFetching,
  error,
  getCategoryName,
} = useCoursesTab();

const selectedRows = ref<CourseTableDataRow[]>([]);
const defaultSort = [{ desc: true, id: "dateOfPublication" }];

const isExportModalOpen = ref(false);
const onStartExport = () => {
  if (selectedRows.value.length > 0) {
    isExportModalOpen.value = true;
  }
};

// FIXME: Hack to get around the thrown AbortError
const notAbortError = computed(() => {
  if (error.value?.name === "AbortError") {
    return null;
  }

  return { title: t("error.title"), description: error.value };
});

const getNoPreviewThumbnail = (e: Event) => {
  (e.target as HTMLImageElement).src = NoPreview;
  (e.target as HTMLImageElement).alt = t("table.no_preview");
};

const filters = ref<Filters>({ ...initialFilters });
const columnFilters = computed(() => [
  {
    id: "language",
    value: filters.value.language
      .filter(Boolean)
      .map(getLocaleWithUpperCaseRegion),
  },
  {
    id: "categoryId",
    value: filters.value.categoryId,
  },
  {
    id: "dateOfPublication",
    value: filters.value.dateOfPublication,
  },
]);
</script>
<template>
  <GStateHandlerWrapper :isLoading="isFetching" :error="notAbortError">
    <template v-if="courseList">
      <GButton
        v-if="!flagStore.actionBarFlag"
        class="mb-4 ml-auto lg:px-12"
        @click="onStartExport"
        :disabled="selectedRows.length === 0"
      >
        <template #prefixIcon><ArrowDownToLineIcon /></template>
        {{ t("common.export") }}
      </GButton>
      <FilterPanel
        v-model:search="filters.search"
        @reset="filters = { ...initialFilters }"
      >
        <template #primaryFilters>
          <LanguageSelect v-model="filters.language" :isMultiChoice="true" />
          <CourseCategoriesSelect v-model="filters.categoryId" />
          <GDatePicker
            v-model="filters.dateOfPublication"
            :label="t('common.select_date')"
          />
        </template>
      </FilterPanel>
      <GTable
        :data="courseList"
        :columns="coursesTableDefinition"
        :filter="{
          global: filters.search,
          columns: columnFilters,
        }"
        :selectedList="selectedRows"
        @selectedListChange="
          (list) => selectedRows.splice(0, selectedRows.length, ...list)
        "
        :defaultSort="defaultSort"
        :exportFn="onStartExport"
      >
        <template v-slot:thumbnail="cellProps">
          <img
            :src="`/ghcc_media/${cellProps.value}`"
            alt=""
            @error="getNoPreviewThumbnail"
            class="w-14 min-w-14 object-cover"
          />
        </template>
        <template v-slot:title="cellProps">
          {{ cellProps.value }}
        </template>
        <template v-slot:dateOfPublication="cellProps">
          {{ (cellProps.value as Date).toLocaleDateString() }}
        </template>
        <template v-slot:categoryId="cellProps">
          {{ getCategoryName(cellProps.value as number) }}
        </template>
        <template v-slot:actions="cellProps">
          <a
            :href="cellProps.row.courseLink"
            target="_blank"
            rel="noopener noreferrer"
            class="flex w-max items-center border-b border-transparent text-primary hover:border-b hover:border-dotted hover:border-primary"
          >
            <span class="mr-1">{{ t("common.open") }}</span>
            <ExternalLinkIcon class="h-4 w-4" />
          </a>
        </template>
      </GTable>
    </template>
  </GStateHandlerWrapper>

  <ExportModal
    :isOpen="isExportModalOpen"
    @close="isExportModalOpen = false"
    @exportSucceeded="() => (selectedRows = [])"
    :selectedRows="selectedRows"
    class="md:w-[25rem]"
  />
</template>
