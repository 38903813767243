import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import { i18n, loadMessages, setI18nLanguage } from "@/i18n";
import { getStartingLocale } from "@/utils/i18n";
import { trackMixPanelEvent } from "@/services/analytics/mixpanel";
import { DASHBOARD_ROLES, userRoles, RouteFlag } from "@/stores";
import { authGuard } from "./auth-guard";
import {
  ContentSelectionPage,
  CoursesTab,
  GoodScanTab,
  SingleActivitiesTab,
  AssessmentsTab,
  LessonsTab,
  ConnectionMethodsPage,
  Lti11Tab,
  Lti13Tab,
  ScormTab,
  ReportsPage,
  CourseReportTab,
  CourseReportDetailsPage,
  CourseMilestonesReportTab,
  UsersReportTab,
  UserReportDetailsPage,
  DetailedUserReportTab,
  TeamMemberReportTab,
  ExportTablePage,
  RecommendPage,
  ManualsDocsTab,
  IconsLeafletsTab,
  PromoStudioTab,
  ResourcesPage,
  NotFoundPage,
  AssessmentsPage,
  LaunchPage,
  DashboardTourPage,
  TeamActivationPage,
  AboutYouPage,
  AiConfigOrganisationSettingsPage,
  OScanPage,
  ManageUsersPage,
  ManageTeamsPage,
  ManageActionLogPage,
  ManageRolesPage,
  DashboardOverviewPage,
} from "@/pages";

const dashboardRoutes: RouteRecordRaw[] = [
  {
    path: "overview",
    name: "Overview",
    component: DashboardOverviewPage,
    meta: {
      allowedRoles: [
        userRoles.admin,
        userRoles.accountManager,
        userRoles.connector,
        userRoles.reporter,
        userRoles.marketer,
      ],
      flags: {
        release: [RouteFlag.NewDashboardOverview],
      },
    },
  },
  {
    path: "organisation",
    name: "Organisation",
    redirect: "/admin-dashboard/organisation/manage-users",
    meta: {
      allowedRoles: [userRoles.admin, userRoles.accountManager],
    },
    children: [
      {
        path: "users",
        name: "ManageUsers",
        component: ManageUsersPage,
        meta: {
          flags: {
            release: [RouteFlag.ManageUsers],
            entitlements: ["entManageUsers"],
          },
        },
      },
      {
        path: "teams",
        name: "ManageTeams",
        component: ManageTeamsPage,
        meta: {
          flags: {
            release: [RouteFlag.ManageGroups],
            entitlements: ["entManageTeam"],
          },
        },
      },
      {
        path: "log",
        name: "ManageActionLog",
        component: ManageActionLogPage,
        meta: {
          flags: {
            release: [RouteFlag.ManageGroups],
            entitlements: ["entManageTeam"],
          },
        },
      },
      {
        path: "roles",
        name: "ManageRoles",
        component: ManageRolesPage,
        meta: {
          flags: {
            release: [RouteFlag.ManageRoles],
            entitlements: ["entManageRoles"],
          },
        },
      },
      {
        path: "oscan-report",
        component: OScanPage,
        meta: {
          flags: {
            release: [RouteFlag.OScanReport],
            entitlements: ["entOscanAccess"],
          },
        },
      },
      {
        path: ":pathMatch(.*)*",
        redirect: "/admin-dashboard/organisation",
      },
    ],
  },
  {
    path: "reports",
    name: "Reports",
    component: ReportsPage,
    redirect: "/admin-dashboard/reports/courses",
    meta: {
      allowedRoles: [userRoles.reporter],
    },
    children: [
      {
        path: "courses",
        component: CourseReportTab,
        meta: {
          flags: {
            release: [RouteFlag.CourseReport],
            entitlements: ["entReportCourse"],
          },
        },
      },
      {
        path: "students",
        component: UsersReportTab,
        meta: {
          allowedRoles: [userRoles.groupReporter],
          flags: {
            release: [RouteFlag.UserReport],
            entitlements: ["entReportIndividual"],
          },
        },
      },
      {
        path: "detailed-users",
        component: DetailedUserReportTab,
        meta: {
          flags: {
            release: [RouteFlag.DetailedUsersReport],
            entitlements: ["entReportActivities"],
          },
        },
      },
      {
        path: "course-milestones",
        component: CourseMilestonesReportTab,
        meta: {
          flags: {
            release: [RouteFlag.CourseMilestonesReport],
            entitlements: ["entReportMilestone"],
          },
        },
      },
      {
        path: "team-members",
        component: TeamMemberReportTab,
        meta: {
          flags: {
            release: [RouteFlag.GroupMembersReport],
            entitlements: ["entReportTeamMembers"],
          },
        },
      },
      { path: ":pathMatch(.*)*", redirect: "/admin-dashboard/reports" },
    ],
  },
  {
    path: "reports/courses/:courseContainerId",
    component: CourseReportDetailsPage,
    meta: {
      allowedRoles: [userRoles.reporter],
      flags: {
        release: [RouteFlag.CourseReport],
        entitlements: ["entReportCourse"],
      },
    },
  },
  {
    path: "reports/students/:studentId",
    component: UserReportDetailsPage,
    meta: {
      allowedRoles: [userRoles.reporter, userRoles.groupReporter],
      flags: {
        release: [RouteFlag.UserReport],
        entitlements: ["entReportIndividual"],
      },
    },
  },
  {
    path: "connect-methods",
    name: "connectionMethods",
    component: ConnectionMethodsPage,
    redirect: "/admin-dashboard/connect-methods/lti11",
    meta: {
      allowedRoles: [userRoles.connector],
    },
    children: [
      {
        path: "lti11",
        component: Lti11Tab,
        meta: { flags: { entitlements: ["entConnectionMethodLti11"] } },
      },
      {
        path: "lti13",
        component: Lti13Tab,
        meta: { flags: { entitlements: ["entConnectionMethodLti13"] } },
      },
      {
        path: "scorm",
        component: ScormTab,
        meta: { flags: { entitlements: ["entConnectionMethodRs"] } },
      },
      {
        path: ":pathMatch(.*)*",
        redirect: "/admin-dashboard/connect-methods",
      },
    ],
  },
  {
    path: "content-selection",
    name: "contentSelection",
    redirect: "/admin-dashboard/content-selection/courses",
    meta: {
      allowedRoles: [userRoles.reporter, userRoles.connector],
    },
    component: ContentSelectionPage,
    children: [
      {
        path: "courses",
        component: CoursesTab,
        meta: {
          flags: {
            entitlements: ["entConnectionSelectionCourses"],
          },
        },
      },
      {
        path: "goodscan",
        component: GoodScanTab,
        meta: {
          flags: {
            entitlements: ["entConnectionSelectionGoodscan"],
          },
        },
      },

      {
        path: "single-activities",
        component: SingleActivitiesTab,
        meta: {
          flags: {
            entitlements: ["entConnectionSelectionSingleActivities"],
          },
        },
      },

      {
        path: "assessments",
        component: AssessmentsTab,
        meta: {
          flags: {
            entitlements: ["entConnectionSelectionAssessments"],
          },
        },
      },
      {
        path: "lessons",
        component: LessonsTab,
        meta: {
          flags: {
            release: [RouteFlag.Lessons],
            entitlements: ["entConnectionSelectionLessons"],
          },
        },
      },
      {
        path: ":pathMatch(.*)*",
        redirect: "/admin-dashboard/content-selection",
      },
    ],
  },
  {
    path: "recommend",
    name: "Recommend",
    meta: {
      allowedRoles: [userRoles.admin, userRoles.accountManager],
    },
    component: RecommendPage,
  },
  {
    path: "resources",
    name: "Resources",
    component: ResourcesPage,
    redirect: "/admin-dashboard/resources/manuals-docs",
    meta: {
      allowedRoles: DASHBOARD_ROLES,
      flags: { release: [RouteFlag.Resources] },
    },
    children: [
      {
        path: "manuals-docs",
        component: ManualsDocsTab,
      },
      {
        path: "icons-leaflets",
        component: IconsLeafletsTab,
      },
      {
        path: "promo-studio",
        component: PromoStudioTab,
      },
      {
        path: ":pathMatch(.*)*",
        redirect: "/admin-dashboard/resources",
      },
    ],
  },
  {
    path: "assessments",
    name: "assessments",
    component: AssessmentsPage,
    meta: {
      allowedRoles: [userRoles.reporter],
      flags: {
        release: [RouteFlag.AssessmentInsights],
        entitlements: ["entReportAssessment"],
      },
    },
  },
  {
    path: "launch",
    name: "launch",
    component: LaunchPage,
    meta: {
      allowedRoles: DASHBOARD_ROLES,
      flags: {
        release: [RouteFlag.SmeAdminOnboarding],
      },
    },
  },
  {
    path: "content-selection/export-data-table/:type",
    name: "exportDataTable",
    component: ExportTablePage,
    props: true,
    meta: {
      allowedRoles: [userRoles.reporter, userRoles.connector],
    },
  },
  {
    path: "ai-config",
    name: "aiConfig",
    meta: {
      allowedRoles: [userRoles.admin, userRoles.accountManager],
    },
    redirect: "/admin-dashboard/ai-config/organisation-settings",
  },
  {
    path: "ai-config/organisation-settings",
    name: "aiConfigOrganisationSettings",
    meta: {
      allowedRoles: [userRoles.admin, userRoles.accountManager],
    },
    component: AiConfigOrganisationSettingsPage,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: NotFoundPage,
  },
];

export const routes = [
  {
    path: "/",
    redirect: "/admin-dashboard/content-selection",
  },
  {
    path: "/admin-dashboard",
    name: "adminDashboard",
    redirect: "/admin-dashboard/content-selection/courses",
    children: dashboardRoutes,
  },
  {
    path: "/onboarding",
    name: "onboarding",
    redirect: "/onboarding/tour",
    meta: { allowedRoles: DASHBOARD_ROLES },
    children: [
      {
        path: "team-activation",
        name: "teamActivation",
        component: TeamActivationPage,
        meta: {
          flags: { release: [RouteFlag.SmeAdminOnboarding] },
        },
      },
      {
        path: "tour",
        name: "dashboardTour",
        component: DashboardTourPage,
        meta: {
          flags: { release: [RouteFlag.SmeAdminOnboarding] },
        },
      },
      {
        path: "about-you",
        name: "onboardingAboutYou",
        component: AboutYouPage,
        meta: { flags: { release: [RouteFlag.SmeAdminOnboarding] } },
      },
      {
        path: ":pathMatch(.*)*",
        name: "notFound",
        component: NotFoundPage,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(getStartingLocale() + "/"),
  routes,
});

router.beforeEach(async (to) => {
  try {
    const targetLocale = getStartingLocale();
    await loadMessages(i18n, targetLocale);

    setI18nLanguage(i18n, targetLocale);

    const canAccess = await authGuard(to);

    return canAccess;
  } catch (error) {
    console.error(error);
    return true;
  }
});

router.afterEach((to) => {
  trackMixPanelEvent("pageView", { path: to.path, context: "Dashboard" });
});

export default router;
