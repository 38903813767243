import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";
import type {
  AnonymiseUsersByIdsResponse,
  ManageUsersTableDataRow,
  UserActionId,
} from "./types";
import { useFetch, useLocale } from "@/composables";
import { useNotificationsStore } from "@/stores";

import {
  USER_STATUS,
  type UserStatusValue,
  userStatusValueToKey,
} from "@/constants/user";

type UserAction = {
  id: UserActionId;
  label: string;
  forStatus: UserStatusValue[];
  isDestructive?: boolean;
  action: () => void;
};

export const useUserActionsStore = defineStore("userActions", () => {
  const { t } = useI18n();
  const { locale } = useLocale();
  const { showNotification } = useNotificationsStore();
  const activeAction = ref<UserActionId | null>(null);
  const activeRow = ref<ManageUsersTableDataRow | null>(null);
  const selectedUsers = ref<Array<ManageUsersTableDataRow>>([]);
  const isActionRequestPending = ref(false);

  const userActions: UserAction[] = [
    {
      id: "resendInvitation",
      label: t("organisation.resend_invitation"),
      action: () => (activeAction.value = "resendInvitation"),
      forStatus: [USER_STATUS.invited],
    },
    {
      id: "revokeInvitation",
      label: t("organisation.revoke_invitation"),
      action: () => (activeAction.value = "revokeInvitation"),
      forStatus: [USER_STATUS.invited],
    },
    {
      id: "addToTeam",
      label: t("organisation.add_to_team"),
      action: () => (activeAction.value = "addToTeam"),
      forStatus: [USER_STATUS.invited, USER_STATUS.active],
    },
    {
      id: "assignRole",
      label: t("organisation.assign_role"),
      action: () => (activeAction.value = "assignRole"),
      forStatus: [USER_STATUS.invited, USER_STATUS.active],
    },
    {
      id: "resetPassword",
      label: t("organisation.reset_password"),
      action: () => (activeAction.value = "resetPassword"),
      forStatus: [USER_STATUS.active],
    },
    {
      id: "export",
      label: t("common.export"),
      action: () => (activeAction.value = "export"),
      forStatus: [USER_STATUS.invited, USER_STATUS.active, USER_STATUS.blocked],
    },
    {
      id: "unblockUser",
      label: t("organisation.unblock"),
      action: () => (activeAction.value = "unblockUser"),
      forStatus: [USER_STATUS.blocked],
    },
    {
      id: "anonymiseUser",
      label: t("organisation.anonymise"),
      action: () => (activeAction.value = "anonymiseUser"),
      forStatus: [USER_STATUS.invited, USER_STATUS.active, USER_STATUS.blocked],
      isDestructive: true,
    },
    {
      id: "blockUser",
      label: t("organisation.block"),
      action: () => (activeAction.value = "blockUser"),
      forStatus: [USER_STATUS.invited, USER_STATUS.active],
      isDestructive: true,
    },
  ];

  const closeModal = () => {
    activeAction.value = null;
  };

  // if the user is opening a row action menu, we need to get the id of the row
  // that is being opened. If the user is selecting multiple rows, we need to
  // get the ids of the selected rows
  const eligibleIdsForAction = computed(() => {
    if (activeRow.value) return [activeRow.value.id];
    return selectedUsers.value.map((user) => user.id);
  });

  const actionsForSelectedRows = computed(() => {
    const selectedItemsStatuses = selectedUsers.value.map(
      (item) => item.status,
    );

    if (selectedItemsStatuses.length === 0) return userActions;

    return userActions.filter((action) =>
      selectedItemsStatuses.some((itemStatus) =>
        action.forStatus.includes(itemStatus),
      ),
    );
  });

  const actionsForRow = computed(() => {
    if (!activeRow.value) return userActions;

    return userActions.filter((action) =>
      action.forStatus.includes(activeRow.value.status),
    );
  });

  const actionsForBulk = computed(() => {
    return userActions;
  });

  const revokeInvitationsByIds = async () => {
    const isSomeSelectedUsersInvited = selectedUsers.value.some(
      (user) => user.status === USER_STATUS.invited,
    );

    if (!isSomeSelectedUsersInvited) {
      return;
    }

    isActionRequestPending.value = true;
    const { error: revokeInvitationsError } = await useFetch(
      "/api/ActivityQueue",
    ).post({
      command: "revoke_by_voucherid",
      // TODO: Ask BE whether we should filter out the users that are not invited
      // on the client side or we just send the whole list of users
      data: eligibleIdsForAction.value.join(","),
      languagecode: locale.value,
    });
    isActionRequestPending.value = false;

    if (revokeInvitationsError.value) {
      showNotification({
        type: "danger",
        message: revokeInvitationsError.value,
      });
    } else {
      showNotification({
        type: "success",
        message: t("organisation.revoke_invitation_success"),
      });
    }

    activeAction.value = null;
  };

  const anonymiseUsersByIds = async () => {
    isActionRequestPending.value = true;
    const { data } = await useFetch("/api/AnonymizeUser/ByUserIds")
      .post({
        personIds: eligibleIdsForAction.value,
      })
      .json<AnonymiseUsersByIdsResponse>();
    isActionRequestPending.value = false;

    if (data.value.excludedEntries.length > 0) {
      showNotification({
        type: "danger",
        message: t("organisation.anonymise_error", {
          n: data.value.excludedEntries.length,
          // TODO: Discuss with David how ti show multiple reasons
          reason: data.value.excludedEntries[0].reason,
        }),
      });
    } else {
      showNotification({
        type: "success",
        message: t("organisation.anonymise_success", {
          n: data.value.enqueuedEntriesCount,
        }),
      });
    }

    activeAction.value = null;
  };

  const blockUsersByIds = async () => {
    isActionRequestPending.value = true;

    const { error: blockUsersError } = await useFetch(
      "/api/ActivityQueue",
    ).post({
      command: "remove_user_by_email",
      data: eligibleIdsForAction.value.join(","),
      languageCode: locale.value,
      removeMailTxt: "no text available", // TODO: ask Rick if this is needed. It was in the ticket
    });
    isActionRequestPending.value = false;

    if (blockUsersError.value) {
      showNotification({
        type: "danger",
        message: blockUsersError.value,
      });
    } else {
      showNotification({
        type: "success",
        message: t("organisation.block_success"),
      });
    }

    activeAction.value = null;
  };

  const exportUsersFileHeaders = computed(() => [
    t("reports.tbl_col_email"),
    t("common.name"),
    t("common.status"),
    // t("reports.team"), // TODO: not available from the BE yet
    // t("common.last_active"), // TODO: not available from the BE yet
  ]);

  const exportUsersFileRows = () => {
    return selectedUsers.value.map((user) => [
      user.email,
      user.name,
      userStatusValueToKey[user.status],
      // user.team, // TODO: not available from the BE yet
      // user.lastActive, // TODO: not available from the BE yet
    ]);
  };

  return {
    actionsForSelectedRows,
    actionsForRow,
    actionsForBulk,
    revokeInvitationsByIds,
    anonymiseUsersByIds,
    blockUsersByIds,
    closeModal,
    activeAction,
    activeRow,
    selectedUsers,
    isActionRequestPending,
    exportUsersFileHeaders,
    exportUsersFileRows,
  };
});

export default useUserActionsStore;
