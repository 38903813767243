import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  sub,
  startOfDay,
  endOfDay,
  endOfToday,
} from "date-fns";
import { useI18n } from "vue-i18n";

export enum PresetId {
  TODAY,
  LAST_7_DAYS,
  THIS_MONTH,
  LAST_30_DAYS,
  THIS_YEAR,
  LAST_12_MONTHS,
}

export function useDatePicker() {
  const { t } = useI18n();

  const defaultPresetRanges: Array<{
    id: PresetId;
    label: string;
    value: [Date, Date];
  }> = [
    {
      id: PresetId.TODAY,
      label: t("common.today"),
      value: [startOfDay(new Date()), new Date()],
    },
    {
      id: PresetId.LAST_7_DAYS,
      label: t("common.count_days", { count: 7 }),
      value: [startOfDay(sub(new Date(), { days: 6 })), endOfToday()],
    },
    {
      id: PresetId.THIS_MONTH,
      label: t("common.this_month"),
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    {
      id: PresetId.LAST_30_DAYS,
      label: t("common.count_days", { count: 30 }),
      value: [startOfDay(sub(new Date(), { days: 29 })), endOfDay(new Date())],
    },
    {
      id: PresetId.THIS_YEAR,
      label: t("common.this_year"),
      value: [startOfYear(new Date()), endOfYear(new Date())],
    },
    {
      id: PresetId.LAST_12_MONTHS,
      label: t("common.count_months", { count: 12 }),
      value: [
        startOfDay(sub(new Date(), { months: 12 })),
        endOfDay(new Date()),
      ],
    },
  ];

  return {
    defaultPresetRanges,
  };
}
