import { computed, type Ref } from "vue";
import { useI18n } from "vue-i18n";
import { formatISO } from "date-fns";
import { storeToRefs } from "pinia";
import type { OnFetchErrorContext } from "@vueuse/core";

import { useFetch, useReportingServiceFetch } from "@/composables";
import {
  useEntitlementsStore,
  useFlagStore,
  useNotificationsStore,
  useUserStore,
  useTeamsStore,
} from "@/stores";
import { userRoles } from "@/stores";
import { formatSecondsToHHMMSS } from "@/utils/misc";
import { createTableDef } from "@/patterns";
import { USER_STATUS } from "@/constants/user";
import { getPayloadTags } from "../utils";
import type { FetchReportPayload } from "../types";
import type {
  ColumnAccessor,
  Filters,
  LearningProgressDetailsReportResponse,
  UsersReport,
} from "./types";

export const useUsersReport = (filters: Ref<Filters>) => {
  const { t } = useI18n();
  const flagStore = useFlagStore();
  const userStore = useUserStore();
  const notificationsStore = useNotificationsStore();
  const teamsStore = useTeamsStore();

  const { checkEntitlementEnabled } = useEntitlementsStore();
  const { currentUserRoles, user } = storeToRefs(userStore);

  const hasReportTeamEntitlement = computed(() =>
    checkEntitlementEnabled("entReportTeam"),
  );

  const isExclusivelyGroupReporter = computed(
    () =>
      currentUserRoles.value.includes(userRoles.groupReporter) &&
      !currentUserRoles.value.includes(userRoles.reporter),
  );

  const isGroupReporterWithNoTeams = computed(
    () => isExclusivelyGroupReporter.value && teamsStore.myTeams.length === 0,
  );

  const teamsPayload = computed(() => {
    if (!isExclusivelyGroupReporter.value) {
      return getPayloadTags(filters.value.teams);
    }

    return filters.value.teams.length === 1 && filters.value.teams[0] === null
      ? teamsStore.myTeams?.map((team) => Object.keys(team)[0]).map(Number)
      : filters.value.teams;
  });

  const fetchLearningProgressDetailsReportDateRange = computed(() => {
    const [dateFrom, dateTo] = filters.value.date;
    return {
      dateFrom,
      dateTo,
    };
  });

  const {
    executeLearningProgressDetailsReportFetch,
    isFetchingLearningProgressDetailsReport,
    learningProgressDetailsReport,
  } = fetchLearningProgressDetailsReport({
    companyName: user.value.CompanyName,
    dateRange: fetchLearningProgressDetailsReportDateRange,
    onError: (ctx) => {
      notificationsStore.showNotification({
        message: "Error fetching learning progress details report",
        type: "danger",
      });
      return ctx;
    },
  });

  const fetchUserReportPayload = computed<FetchReportPayload>(() => ({
    SearchFor: filters.value.search,
    DateFrom: filters.value.date[0]
      ? formatISO(filters.value.date[0], { representation: "date" })
      : null,
    DateTo: filters.value.date[1]
      ? formatISO(filters.value.date[1], { representation: "date" })
      : null,
    Tags: teamsPayload.value,
    PersonStatusId: filters.value.status,
    CourseMilestoneId: 0,
    isOptingInNewDashboard: true,
  }));

  const {
    isFetching: isFetchingReport,
    error,
    data,
  } = useFetch("api/ReportStudent", {
    refetch: true,
    immediate: !isExclusivelyGroupReporter.value,
  })
    .post(fetchUserReportPayload)
    .json<UsersReport[]>();

  const usersReport = computed(() =>
    isGroupReporterWithNoTeams.value ? [] : data.value,
  );

  const hasForeignUsername = computed(
    () =>
      flagStore.reportsForeignUsernameFlag &&
      usersReport.value?.some((user) => user.ForeignUsername),
  );

  const tableDefinition = computed(() =>
    createTableDef<ColumnAccessor>(
      [
        { id: "report", headerLabel: t("reports.report") },
        {
          id: "email",
          headerLabel: t("reports.tbl_col_email"),
          accessor: "email",
          isGloballyFilterable: true,
        },
        {
          id: "foreignUsername",
          headerLabel: t("reports.tbl_col_foreign_username"),
          accessor: "foreignUsername",
        },
        {
          id: "name",
          headerLabel: t("reports.tbl_col_name"),
          accessor: "name",
        },
        {
          id: "teams",
          headerLabel: t("reports.teams"),
          accessor: "teams",
        },
        {
          id: "lessonVisitsCount",
          headerLabel: t("reports.tbl_col_lesson_visits"),
          accessor: "lessonVisitsCount",
        },
        {
          id: "studyTime",
          headerLabel: t("reports.tbl_col_study_time_hhmmss"),
          accessor: "studyTime",
        },
        {
          id: "coursesStartedCount",
          headerLabel: t("reports.tbl_col_course_started"),
          accessor: "coursesStartedCount",
        },
        {
          id: "coursesCertificateCount",
          headerLabel: t("reports.tbl_col_course_completed"),
          accessor: "coursesCertificateCount",
        },
        {
          id: "testsCertificateCount",
          headerLabel: t("reports.tbl_col_test_passed"),
          accessor: "testsCertificateCount",
        },
        {
          id: "hasGoodScan",
          headerLabel: t("usersReport.tbl_col_has_good_scan"),
          accessor: "hasGoodScan",
        },
        {
          id: "studentId",
          accessor: "studentId",
          visibleFrom: false,
        },
      ],
      {
        teams: !!hasReportTeamEntitlement.value,
        foreignUsername: hasForeignUsername.value,
      },
    ),
  );

  const userStatusOptions = computed(() => {
    return [
      { value: -1, label: t("common.all") },
      { value: USER_STATUS.created, label: t("user_status.created") },
      { value: USER_STATUS.invited, label: t("user_status.invited") },
      { value: USER_STATUS.active, label: t("user_status.active") },
      { value: USER_STATUS.blocked, label: t("user_status.blocked") },
    ];
  });

  return {
    usersReport,
    error,
    isFetching: isFetchingReport,
    learningProgressDetailsReport,
    isFetchingLearningProgressDetailsReport,
    executeLearningProgressDetailsReportFetch,
    data: computed(() => mapUsersDataToTableData(usersReport.value)),
    tableDefinition,
    hasReportTeamEntitlement,
    hasForeignUsername,
    userStatusOptions,
    isUserReporter: computed(() =>
      currentUserRoles.value.includes(userRoles.reporter),
    ),
  };
};

type FetchLearningProgressDetailsReportInput = {
  companyName: string;
  dateRange: Ref<{ dateFrom: Date; dateTo: Date }>;
  onError: (ctx: OnFetchErrorContext) => Partial<OnFetchErrorContext>;
};

export const fetchLearningProgressDetailsReport = ({
  companyName,
  dateRange,
  onError,
}: FetchLearningProgressDetailsReportInput) => {
  const { data, isFetching, execute } = useReportingServiceFetch(
    "api/learningprogressdetails",
    {
      immediate: false,
      onFetchError: onError,
    },
  )
    .post(dateRange)
    .json<LearningProgressDetailsReportResponse[]>();

  const safeExecute = async () => {
    try {
      await execute(true);
      return true;
    } catch {
      return false;
    }
  };

  const mapResponseToReport = (
    item: LearningProgressDetailsReportResponse,
  ) => ({
    companyName,
    courseId: item.courseid,
    courseLanguage: item.courselanguage,
    courseName: item.coursename,
    courseStart: item.coursestart,
    username: item.username,
    email: item.email,
    fuid: item.fuid ?? "",
    progress: item.progress,
    studyTime: item.studytime,
    lessonCertificate: item.lessoncertificate,
    testCertificate: item.testcertificate,
  });

  return {
    learningProgressDetailsReport: computed(
      () => data.value?.map(mapResponseToReport) ?? [],
    ),
    isFetchingLearningProgressDetailsReport: isFetching,
    executeLearningProgressDetailsReportFetch: safeExecute,
  };
};

const mapUsersDataToTableData = (users: UsersReport[] | null) => {
  return users
    ?.map((user) => ({
      email: user.Email,
      name: `${user.LastName}, ${user.FirstName} ${user.MidName}`.trim(),
      teams: user.Tags,
      lessonVisitsCount: user.ModuleCount,
      studyTime: formatSecondsToHHMMSS(user.ContentSeconds),
      coursesStartedCount: user.CoursesStarted,
      coursesCertificateCount: user.CoursesCompleted,
      testsCertificateCount: user.TestsPassed,
      hasGoodScan: user.GoodScanDone ? "✓" : "-",
      studentId: user.PersonId,
      foreignUsername: user.ForeignUsername,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
};
