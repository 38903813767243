<script setup lang="ts">
import { computed, toRef } from "vue";
import { addDays, differenceInDays, getMonth, getTime } from "date-fns";
import { InsightCard, BarChart } from "@/components";
import { useSectionFetch } from "./useSectionFetch";

type CompletedAssessmentsResponse = {
  day: number;
  count: number;
}[];

const props = defineProps<{
  startDate: number;
  endDate: number;
}>();

const startDateRef = toRef(props, "startDate");
const endDateRef = toRef(props, "endDate");

const { execute, data, isFetching, error } =
  useSectionFetch<CompletedAssessmentsResponse>(
    "CompletedAssessmentsReport",
    startDateRef,
    endDateRef
  );

const completedAssessmentsDataSet = computed(() => {
  let xValues: number[] = [];
  let yValues: number[] = [];
  if (data.value) {
    // if less then 60 days, show days individually
    if (differenceInDays(props.endDate, props.startDate) < 60) {
      xValues = data.value.map((dataPoint) =>
        getTime(addDays(props.startDate, dataPoint.day))
      );

      yValues = data.value.map((dataPoint) => dataPoint.count);
      // else group them by month
    } else {
      let month = -1;
      let countForMonth = 0;
      for (let i = 0; i < data.value.length; i++) {
        const dataPoint = data.value[i];
        const date = getTime(addDays(props.startDate, dataPoint.day));
        if (month === getMonth(date)) {
          countForMonth += dataPoint.count;
        } else {
          xValues.push(date);
          if (i !== 0) yValues.push(countForMonth);
          month = getMonth(date);
          countForMonth = dataPoint.count;
        }
        if (data.value.length - 1 === i) yValues.push(countForMonth);
      }
    }
  }
  return { labels: xValues, data: yValues };
});
</script>
<template>
  <InsightCard
    class="lg:col-span-2"
    :title="$t('assessments.completed_title')"
    :subtitle="$t('assessments.completed_subtitle')"
    :isLoading="isFetching"
    :error="error"
    @retry="execute"
  >
    <BarChart
      class="mt-4"
      :xValues="completedAssessmentsDataSet.labels"
      :yValues="completedAssessmentsDataSet.data"
    ></BarChart
  ></InsightCard>
</template>
